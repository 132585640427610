const ASSETS = {
  whatsapp: "/media/icons/whatsapp.png", // source, channel
  voiceCall: "/media/icons/telephone.png", // source
  FBMessenger: "/media/icons/facebook.png", // source
  FBMessaging: "/media/icons/facebook.png", // channel
  webchat: "/media/icons/webchat.png", // channel
  facebook: "/media/icons/facebook.png", // channel
  FBLead: "/media/icons/facebook.png", // source
  instagram: "/media/icons/instagram.png", // channel
  form: "/media/icons/website.png",
  sms: "/media/icons/sms.png",
  manual: "/media/icons/manual.png",
  messenger: "/media/icons/messenger.png",
  invoice: "/media/icons/invoice.png",
  verified: "/media/icons/verified.png",
  "video/mp4": "/media/icons/mp4.png",
  "video/mpeg": "/media/icons/mpeg.png",
  "audio/mpeg": "/media/icons/mp3.png",
  "image/jpeg": "/media/icons/jpeg.png",
  "image/png": "/media/icons/png.png",
  "application/json": "/media/icons/json.png",
  "application/zip": "/media/icons/zip.png",
  "application/vnd.rar": "/media/icons/rar.png",
  "application/pdf": "/media/icons/pdf.png",
  "audio/ogg": "/media/icons/ogg.png",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "/media/icons/sheets.png",
  logo: "/media/logo.svg",
  favicon: "/media/favicon.ico",
  tr: "/media/flags/tr.svg",
  en: "/media/flags/us.svg",
  de: "/media/flags/de.svg",
  es: "/media/flags/es.svg",
  az: "/media/flags/az.svg",
  cloud: "/media/icons/cloud.svg",
  "american-express": "/media/cards/american-express.svg",
  mastercard: "/media/cards/mastercard.svg",
  visa: "/media/cards/visa.svg",
  robot: "/media/icons/robot.png",
  train: "/media/icons/train.png",
  flight: "/media/icons/plane.png",
  ship: "/media/icons/ship.png",
  bus: "/media/icons/bus.png",
};

export default ASSETS;
